export const environment = {
  production: false,
  isAdminSite: true,
  isMultiTenant: true,
  disableSSO: false,
  name: "dev",
  storageHubUrl: 'http://here_should_be_api_url_for_dev_env/hub.html',
  apiUrl: 'http://here_should_be_api_url_for_dev_env',
  containerId: '',
  googleAuthClientId: '',
  featureFlagEnvironmentID: '2Dijf7xZa6CrwYemiJLRiF',
  featureFlagEnableCache: false
};
